import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

//importing screens
import LandingPage from './Screens/LandingPage/LandingPage';
import Layout from './Screens/Layout/Layout';
import ContactUs from './Screens/Contactus/ContactUs';
import AboutUs from './Screens/AboutUs/AboutUs';
import LaptopScreen from './Screens/LaptopScreen/LaptopScreen';
import LaptopDetailsScreen from './Screens/LaptopDetailsScreen/LaptopDetailsScreen';
import NotFoundPage from './Screens/NotFoundPage/NotFoundPage';
import Blog from './Screens/Blog/Blog';
import SearchScreen from './Screens/SearchScreen/SearchScreen';
// const LandingPage = lazy(() => import('./Screens/LandingPage/LandingPage'));

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path='/' element={<LandingPage />} />
          <Route path='search' element={<SearchScreen />} />
          <Route path='contact-us' element={<ContactUs />} />
          <Route path='about-us' element={<AboutUs />} />
          <Route path='category/:cid' element={<LaptopScreen />} />
          <Route path='category/:cid/brand/:bid' element={<LaptopScreen />} />
          <Route
            path='product-details/:params'
            element={<LaptopDetailsScreen />}
          />
          <Route path='blog' element={<Blog />} />
        </Route>
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

{
  /* <Suspense fallback={<div>Loading...</div>}>
              <LandingPage />
            </Suspense> */
}
