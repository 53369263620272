import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { StyledEngineProvider } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Buttons from '../../Components/Buttons/Buttons';

import Paragraphs from '../../Components/Buttons/Paragraphs';

import MaxWidthWrapper from '../../Components/MaxWidthWrapper';
import '../../Css/LandingPageCss/AccordionLanding.css';
import '../../App.css';
function AccordionLanding() {
  return (
    <div className='accordion-parent-div'>
      <MaxWidthWrapper>
        <div className='accordion-div'>
          <div className='accordion-top-container'>
            <p className='common-font accordion-top-container-heading'>
              Frequently asked questions
            </p>
            <p className='common-font accordion-top-container-subheading'>
              Frequently asked questions ordered by popularity. Remember that if
              the visitor has not committed to the call to action, they may
              still have questions (doubts) that can be answered.
            </p>
          </div>

          <div className='accordion-center-container'>
            <StyledEngineProvider injectFirst>
              <Accordion className='accordion-center-container-main'>
                <AccordionSummary
                  className='accordion-center-container-summary'
                  expandIcon={
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <path d='M6 9L12 15L18 9' stroke='black' />
                    </svg>
                  }
                  aria-controls='panel1-content'
                  id='panel1-header'
                >
                  What are open Box Laptops?
                </AccordionSummary>
                <AccordionDetails>
                  Open box laptops offer a budget-friendly option for those
                  looking for a new device. These laptops usually remain unused,
                  often having been used as display models in retail settings.
                  While not brand new, they are in excellent condition and can
                  provide significant savings.
                </AccordionDetails>
              </Accordion>
              <Accordion className='accordion-center-container-main'>
                <AccordionSummary
                  className='accordion-center-container-summary'
                  expandIcon={
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <path d='M6 9L12 15L18 9' stroke='black' />
                    </svg>
                  }
                  aria-controls='panel1-content'
                  id='panel1-header'
                >
                  Why one should choose for Open Box laptop?
                </AccordionSummary>
                <AccordionDetails>
                  "Looking for a budget-friendly laptop option? Consider buying
                  an open box model at Laptop Square! <br />
                  Here are the benefits: <br />
                  <br />* <b>Save money</b>: These laptops are significantly
                  cheaper than brand new ones.
                  <br />* <b>Like new</b>: They're in excellent condition,
                  almost indistinguishable from new laptops.
                  <br />* <b>Quick delivery</b>: Open box laptops at Laptop
                  Square are readily available, unlike new ones which may have
                  longer delivery times. <br />* <b>Stable value</b>: These
                  devices have already taken the biggest depreciation hit, so
                  their value will remain more stable.
                  <br />
                  <br />
                  By choosing an open box laptop, you can enjoy substantial cost
                  savings without compromising on performance or quality. It's a
                  smart way to get a{' '}
                  <b>high-end device at a discounted price</b>!"
                </AccordionDetails>
              </Accordion>
              <Accordion className='accordion-center-container-main'>
                <AccordionSummary
                  className='accordion-center-container-summary'
                  expandIcon={
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <path d='M6 9L12 15L18 9' stroke='black' />
                    </svg>
                  }
                  aria-controls='panel1-content'
                  id='panel1-header'
                >
                  What kind of warranty does Laptop Square offers?
                </AccordionSummary>
                <AccordionDetails>
                  Laptop Square provides its customers with a comprehensive
                  warranty package. This includes:
                  <br /> <br />* A 7-day full cash back return policy, allowing
                  customers to return their laptops for any reason within a week
                  of purchase, provided the product is not damaged/burnt and all
                  stickers remain intact.
                  <br />
                  * A 20-day check warranty, which covers any malfunctioning or
                  defects in the product. <br /> <br />
                  If you're not satisfied with your purchase or encounter any
                  issues, you can return or exchange your laptop within the
                  specified timeframe. This warranty ensures peace of mind and
                  protects your investment. ."
                  <br /> <br />
                  Note: Warranty is valid provided the product is not
                  damaged or burnt.
                </AccordionDetails>
              </Accordion>
              <Accordion className='accordion-center-container-main'>
                <AccordionSummary
                  className='accordion-center-container-summary'
                  expandIcon={
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <path d='M6 9L12 15L18 9' stroke='black' />
                    </svg>
                  }
                  aria-controls='panel1-content'
                  id='panel1-header'
                >
                  What Gifts are given with Laptops if Customer purchase the
                  laptops from Laptop Square?
                </AccordionSummary>
                <AccordionDetails>
                  At Laptop Square, we highly value our customers and offer
                  exclusive gifts with laptop purchases! Get a premium
                  export-quality laptop bag or backpack, carefully selected to
                  meet your needs. Choose from a range of options to find the
                  perfect fit. Plus, your laptop will be securely packed in a
                  high-quality box to ensure safe transportation. Our goal is to
                  exceed your expectations and provide a satisfying shopping
                  experience. Enjoy your new laptop!
                </AccordionDetails>
              </Accordion>
              <Accordion className='accordion-center-container-main'>
                <AccordionSummary
                  className='accordion-center-container-summary'
                  expandIcon={
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <path d='M6 9L12 15L18 9' stroke='black' />
                    </svg>
                  }
                  aria-controls='panel1-content'
                  id='panel1-header'
                >
                  Does Laptop Square offer tailored laptop solutions to cater to
                  the specific needs of individuals and businesses in Pakistan,
                  providing customized configurations, and personalized
                  services?
                </AccordionSummary>
                <AccordionDetails>
                  At Laptop Square, we take pride in exceeding customer
                  expectations. If the desired product is not readily available
                  in our inventory or the local market, our dedicated team will
                  go the extra mile to source it, leveraging our extensive
                  network and resources to ensure that our customers' specific
                  needs are met. We are committed to delivering tailored
                  solutions and unparalleled service, leaving no stone unturned
                  in our pursuit of customer satisfaction.
                </AccordionDetails>
              </Accordion>

              <Accordion className='accordion-center-container-main'>
                <AccordionSummary
                  className='accordion-center-container-summary'
                  expandIcon={
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <path d='M6 9L12 15L18 9' stroke='black' />
                    </svg>
                  }
                  aria-controls='panel1-content'
                  id='panel1-header'
                >
                  Does Laptop Square sell computer accessories?
                </AccordionSummary>
                <AccordionDetails>
                  Laptop Square is your one-stop shop for all computer
                  accessories in Pakistan, offering competitive pricing and a
                  wide range of products. Browse our accessories section for a
                  variety of options, and if you can't find what you're looking
                  for, simply contact us at 03001230326. Our expert team will be
                  happy to assist you in finding the perfect accessory for your
                  laptop, ensuring a seamless shopping experience.
                </AccordionDetails>
              </Accordion>
            </StyledEngineProvider>
          </div>
          <div className='accordion-bottom-container'>
            <p className='common-font accordion-top-container-heading'>
              Still have a questions?
            </p>
            <p className='common-font accordion-top-container-heading accordion-bottom-container-extra'>
              Support details to capture customers that might be on the fence.
            </p>

            <Buttons
              size='large'
              variant='outlined1'
              border='no-border'
              onClick={() => {
                const phoneNumber = '923001230326';
                const text = 'How may I help you';
                const whatsappLink = `//api.whatsapp.com/send?phone=${phoneNumber}&text=${text}`;
                window.open(whatsappLink, '_blank');
              }}
            >
              <Paragraphs
                size='plarge'
                variant='poutlined1'
                border='pno-border'
              >
                Contact us
              </Paragraphs>
            </Buttons>
          </div>
        </div>
      </MaxWidthWrapper>
    </div>
  );
}

export default AccordionLanding;
