const baseUrl = 'https://admin.laptopsquare.pk/api/web/';

export const getAllproducts = baseUrl + 'get-all-products';
export const getCategoryProducts = baseUrl + 'get-products-category';
export const getBrandProducts = baseUrl + 'get-products-brand';
export const getSingleProduct = baseUrl + 'get-product';
export const getBrands = baseUrl + 'brands';
export const getcategories = baseUrl + 'product-categories';
export const contactus = baseUrl + 'contact';
export const search = baseUrl + 'search-product';
