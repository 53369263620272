import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../Css/NotFoundPage/NotFoundPage.css';
import MaxWidthWrapper from '../../Components/MaxWidthWrapper';
import Buttons from '../../Components/Buttons/Buttons';
import Paragraphs from '../../Components/Buttons/Paragraphs';

import notfound from '../../assets/images/notfoun.svg';
function NotFoundPage() {
  const navigate = useNavigate();
  return (
    <div className='page-not-found-top-div'>
      <MaxWidthWrapper>
        <div className='page-not-found-inner-div'>
          <img
            src={notfound}
            alt='Page not found'
            className='page-not-found-image'
          />

          <p className='common-font notfount-heading'>Oh no! Error 404</p>
          <p className='common-font notfount-subheading'>
            Something went wrong, so this page is broken.
          </p>
          <Buttons
            size='large'
            variant='contained'
            border='have-border'
            onClick={() => {
              navigate('/');
            }}
          >
            <Paragraphs
              size='plarge'
              variant='pcontained'
              border='phave-border'
            >
              Go back to Homepage
            </Paragraphs>
          </Buttons>
        </div>
      </MaxWidthWrapper>
    </div>
  );
}

export default NotFoundPage;
