import React from 'react';
import '../Css/Components/CategoriesComponent.css';
import '../App.css';
import { useNavigate } from 'react-router-dom';
function CategoriesComponent({ icon, text, id }) {
  const navigate = useNavigate();
  return (
    <div
      className='catergory-component-container'
      onClick={() => {
        navigate(`/category/${encodeURIComponent(id)}`);
      }}
    >
      <img src={icon} alt='Icon image' className='catergory-component-img' />
      <p className='common-font catergory-component-text'>{text}</p>
    </div>
  );
}

export default CategoriesComponent;
