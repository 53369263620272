import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getcategories, getBrands } from '../../Api/ApiUrl';
import { useNavigate } from 'react-router-dom';
import MaxWidthWrapper from '../../Components/MaxWidthWrapper';
import Divider from '@mui/material/Divider';

import '../../Css/LandingPageCss/Subnavbar.css';
function Subnavbar() {
  const navigate = useNavigate();
  const [AllBrands, setAllBrands] = useState([]);
  const [categories, setCategories] = useState([]);

  const [hideProducts, sethideProducts] = useState(true);

  useEffect(() => {
    const getProductDetails = async () => {
      try {
        const brands = await axios.get(getBrands);
        console.log('I got data FROM backend', brands?.data?.data);
        setAllBrands(brands?.data?.data);
        console.log('ALL BRANDS', brands?.data?.data);

        sethideProducts(false);
      } catch (err) {
        //console.log(err);
      }
    };
    getProductDetails();

    const getCategories = async () => {
      try {
        const categoriesDetails = await axios.get(getcategories);
        console.log('I got data FROM backend', categoriesDetails?.data?.data);
        const updatedCategories = categoriesDetails?.data?.data.slice(1);
        setCategories(updatedCategories);

        sethideProducts(false);
      } catch (err) {
        //console.log(err);
      }
    };
    getCategories();
  }, []);

  return (
    <div className='sub-nav-bar-top-container'>
      <MaxWidthWrapper>
        <div className='sub-nav-bar-container'>
          <div className='sub-nav-bar-container-left-div'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='22'
              height='17'
              viewBox='0 0 22 17'
              fill='none'
            >
              <rect
                x='1'
                y='13'
                width='20'
                height='3'
                rx='1'
                stroke='#544845'
                stroke-width='1.5'
              />
              <rect
                x='2'
                y='1'
                width='18'
                height='12'
                rx='1'
                stroke='#544845'
                stroke-width='1.5'
              />
              <path
                d='M13 1.5L9 1.5'
                stroke='#544845'
                stroke-width='2.5'
                stroke-linecap='round'
              />
            </svg>
            <p className='common-font'>Laptop</p>

            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                d='M18 9L12 15L6 9'
                stroke='#544845'
                stroke-width='1.5'
                stroke-linecap='round'
              />
            </svg>
            {hideProducts ? (
              <>
                <Divider
                  orientation='vertical'
                  variant='middle'
                  flexItem
                  style={{
                    borderColor: 'var(--bownbody)',
                    opacity: 0.2,
                  }}
                />
                <div className='sub-nav-bar-container-left-div sub-nav-bar-container-center-div'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='25'
                    height='24'
                    viewBox='0 0 25 24'
                    fill='none'
                  >
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M12.05 2.00001H12.45C14.2543 1.99735 15.9854 2.71292 17.2613 3.98874C18.5371 5.26457 19.2527 6.99572 19.25 8.80001V15.2C19.2527 17.0043 18.5371 18.7354 17.2613 20.0113C15.9854 21.2871 14.2543 22.0027 12.45 22H12.05C10.2457 22.0027 8.51457 21.2871 7.23874 20.0113C5.96292 18.7354 5.24735 17.0043 5.25001 15.2V8.80001C5.24735 6.99572 5.96292 5.26457 7.23874 3.98874C8.51457 2.71292 10.2457 1.99735 12.05 2.00001ZM12.45 20.5C15.3748 20.4945 17.7445 18.1248 17.75 15.2V8.80001C17.7445 5.87518 15.3748 3.50551 12.45 3.50001H12.05C9.12518 3.50551 6.75551 5.87518 6.75001 8.80001V15.2C6.75551 18.1248 9.12518 20.4945 12.05 20.5H12.45Z'
                      fill='#544845'
                    />
                    <path
                      d='M12.25 6.25001C11.8381 6.2554 11.5054 6.58805 11.5 7.00001V12C11.5 12.4142 11.8358 12.75 12.25 12.75C12.6642 12.75 13 12.4142 13 12V7.00001C12.9946 6.58805 12.662 6.2554 12.25 6.25001Z'
                      fill='#544845'
                    />
                  </svg>
                  <p className='common-font'>Laptop Accessories</p>

                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <path
                      d='M18 9L12 15L6 9'
                      stroke='#544845'
                      stroke-width='1.5'
                      stroke-linecap='round'
                    />
                  </svg>
                </div>
              </>
            ) : (
              <ul className='dropdown'>
                {AllBrands.map((data, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        navigate(
                          `/category/2/brand/${encodeURIComponent(data?.id)}`
                        );
                      }}
                    >
                      {data?.attributes?.name}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          <Divider
            orientation='vertical'
            variant='middle'
            flexItem
            style={{
              borderColor: 'var(--bownbody)',
              opacity: 0.2,
            }}
          />
          <div className='sub-nav-bar-container-left-div sub-nav-bar-container-center-div'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='25'
              height='24'
              viewBox='0 0 25 24'
              fill='none'
            >
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M12.05 2.00001H12.45C14.2543 1.99735 15.9854 2.71292 17.2613 3.98874C18.5371 5.26457 19.2527 6.99572 19.25 8.80001V15.2C19.2527 17.0043 18.5371 18.7354 17.2613 20.0113C15.9854 21.2871 14.2543 22.0027 12.45 22H12.05C10.2457 22.0027 8.51457 21.2871 7.23874 20.0113C5.96292 18.7354 5.24735 17.0043 5.25001 15.2V8.80001C5.24735 6.99572 5.96292 5.26457 7.23874 3.98874C8.51457 2.71292 10.2457 1.99735 12.05 2.00001ZM12.45 20.5C15.3748 20.4945 17.7445 18.1248 17.75 15.2V8.80001C17.7445 5.87518 15.3748 3.50551 12.45 3.50001H12.05C9.12518 3.50551 6.75551 5.87518 6.75001 8.80001V15.2C6.75551 18.1248 9.12518 20.4945 12.05 20.5H12.45Z'
                fill='#544845'
              />
              <path
                d='M12.25 6.25001C11.8381 6.2554 11.5054 6.58805 11.5 7.00001V12C11.5 12.4142 11.8358 12.75 12.25 12.75C12.6642 12.75 13 12.4142 13 12V7.00001C12.9946 6.58805 12.662 6.2554 12.25 6.25001Z'
                fill='#544845'
              />
            </svg>
            <p className='common-font'>Laptop Accessories</p>

            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                d='M18 9L12 15L6 9'
                stroke='#544845'
                stroke-width='1.5'
                stroke-linecap='round'
              />
            </svg>

            <ul className='dropdown'>
              {categories.map((data, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => {
                      navigate(`/category/${encodeURIComponent(data?.id)}`);
                    }}
                  >
                    {data?.attributes?.name}
                  </li>
                );
              })}
            </ul>
          </div>
          <Divider
            orientation='vertical'
            variant='middle'
            flexItem
            style={{
              borderColor: 'var(--bownbody)',
              opacity: 0.2,
            }}
          />
          <div className='sub-nav-bar-container-left-div sub-nav-bar-container-center-div'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='25'
              height='24'
              viewBox='0 0 25 24'
              fill='none'
            >
              <path
                d='M16.3101 20.1805C16.0501 20.1805 15.7801 20.1705 15.4901 20.1405C14.9701 20.1005 14.3801 20.0005 13.7701 19.8505L12.0901 19.4505C7.48007 18.3605 5.97007 15.9205 7.05007 11.3205L8.03007 7.1305C8.25007 6.1805 8.51007 5.4105 8.83007 4.7705C10.5501 1.2205 13.8401 1.5405 16.1801 2.0905L17.8501 2.4805C20.1901 3.0305 21.6701 3.9005 22.5001 5.2305C23.3201 6.5605 23.4501 8.2705 22.9001 10.6105L21.9201 14.7905C21.0601 18.4505 19.2701 20.1805 16.3101 20.1805ZM13.6201 3.2505C11.9501 3.2505 10.8901 3.9405 10.1801 5.4205C9.92007 5.9605 9.69007 6.6305 9.49007 7.4705L8.51007 11.6605C7.62007 15.4405 8.65007 17.0905 12.4301 17.9905L14.1101 18.3905C14.6501 18.5205 15.1601 18.6005 15.6201 18.6405C18.3401 18.9105 19.6901 17.7205 20.4501 14.4505L21.4301 10.2705C21.8801 8.3405 21.8201 6.9905 21.2201 6.0205C20.6201 5.0505 19.4401 4.3905 17.5001 3.9405L15.8301 3.5505C15.0001 3.3505 14.2601 3.2505 13.6201 3.2505Z'
                fill='#544845'
              />
              <path
                d='M8.82907 22.2497C6.25908 22.2497 4.61907 20.7097 3.56907 17.4597L2.28907 13.5097C0.869075 9.10968 2.13907 6.62968 6.51907 5.20968L8.09907 4.69968C8.61907 4.53968 9.00907 4.42968 9.35907 4.36968C9.64907 4.30968 9.92908 4.41968 10.0991 4.64968C10.2691 4.87968 10.2991 5.17968 10.1791 5.43968C9.91908 5.96968 9.68907 6.63968 9.49907 7.47968L8.51908 11.6697C7.62908 15.4497 8.65907 17.0997 12.4391 17.9997L14.1191 18.3997C14.6591 18.5297 15.1691 18.6097 15.6291 18.6497C15.9491 18.6797 16.2091 18.8997 16.2991 19.2097C16.3791 19.5197 16.2591 19.8397 15.9991 20.0197C15.3391 20.4697 14.5091 20.8497 13.4591 21.1897L11.8791 21.7097C10.7291 22.0697 9.72907 22.2497 8.82907 22.2497ZM8.27908 6.21968L6.98907 6.63968C3.41907 7.78968 2.56907 9.46968 3.71907 13.0497L4.99907 16.9997C6.15907 20.5697 7.83908 21.4297 11.4091 20.2797L12.9891 19.7597C13.0491 19.7397 13.0991 19.7197 13.1591 19.6997L12.0991 19.4497C7.48908 18.3597 5.97907 15.9197 7.05907 11.3197L8.03907 7.12968C8.10908 6.80968 8.18907 6.49968 8.27908 6.21968Z'
                fill='#544845'
              />
              <path
                d='M17.9894 10.5098C17.9294 10.5098 17.8694 10.4998 17.7994 10.4898L12.9494 9.25978C12.5494 9.15978 12.3094 8.74978 12.4094 8.34978C12.5094 7.94978 12.9194 7.70978 13.3194 7.80978L18.1694 9.03978C18.5694 9.13978 18.8094 9.54978 18.7094 9.94978C18.6294 10.2798 18.3194 10.5098 17.9894 10.5098Z'
                fill='#544845'
              />
              <path
                d='M15.0592 13.8909C14.9992 13.8909 14.9392 13.8809 14.8692 13.8709L11.9592 13.1309C11.5592 13.0309 11.3192 12.6209 11.4192 12.2209C11.5192 11.8209 11.9292 11.5809 12.3292 11.6809L15.2392 12.4209C15.6392 12.5209 15.8792 12.9309 15.7792 13.3309C15.6992 13.6709 15.3992 13.8909 15.0592 13.8909Z'
                fill='#544845'
              />
            </svg>
            <p className='common-font'>Blogs</p>
          </div>
          <Divider
            orientation='vertical'
            variant='middle'
            flexItem
            style={{
              borderColor: 'var(--bownbody)',
              opacity: 0.2,
            }}
          />
          <div className='sub-nav-bar-container-left-div sub-nav-bar-container-center-div'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='25'
              height='24'
              viewBox='0 0 25 24'
              fill='none'
            >
              <path
                d='M16.9906 22.6695C16.5606 22.6695 16.0206 22.5395 15.3506 22.1395L13.1106 20.8095C12.9406 20.7095 12.5606 20.7095 12.3906 20.8095L10.1506 22.1395C8.73061 22.9795 7.87061 22.6295 7.49061 22.3495C7.11061 22.0695 6.51063 21.3495 6.88063 19.7495L7.41062 17.4395C7.45062 17.2595 7.35062 16.9195 7.22062 16.7795L5.36061 14.9195C4.36061 13.9195 4.44061 13.0495 4.58061 12.6095C4.72061 12.1695 5.16061 11.4195 6.55061 11.1795L8.94062 10.7795C9.11062 10.7495 9.38063 10.5495 9.45063 10.3995L10.7706 7.75952C11.4206 6.46952 12.2806 6.26953 12.7406 6.26953C13.2006 6.26953 14.0606 6.45952 14.7106 7.75952L16.0306 10.3995C16.1106 10.5495 16.3806 10.7495 16.5406 10.7795L18.9306 11.1795C20.3206 11.4095 20.7606 12.1695 20.9006 12.6095C21.0406 13.0495 21.1206 13.9195 20.1206 14.9195L18.2606 16.7795C18.1306 16.9095 18.0306 17.2595 18.0706 17.4395L18.6006 19.7395C18.9706 21.3495 18.3806 22.0595 17.9906 22.3395C17.8106 22.4995 17.4706 22.6695 16.9906 22.6695ZM12.7506 19.2395C13.1506 19.2395 13.5506 19.3295 13.8706 19.5195L16.1106 20.8495C16.7006 21.1995 17.0506 21.1995 17.1206 21.1395C17.2006 21.0795 17.3006 20.7495 17.1506 20.0895L16.6206 17.7895C16.4606 17.1095 16.7206 16.2295 17.2106 15.7395L19.0706 13.8795C19.4406 13.5095 19.5206 13.2195 19.4806 13.0895C19.4406 12.9595 19.2106 12.7695 18.6906 12.6895L16.3006 12.2895C15.6606 12.1795 14.9806 11.6795 14.6906 11.0995L13.3706 8.45953C13.1406 8.00953 12.8906 7.79953 12.7406 7.79953C12.5906 7.79953 12.3406 7.99953 12.1106 8.45953L10.7906 11.0995C10.5006 11.6795 9.82061 12.1795 9.18061 12.2895L6.79063 12.6895C6.27063 12.7795 6.04062 12.9595 6.00062 13.0895C5.96062 13.2195 6.04062 13.5095 6.41062 13.8795L8.27061 15.7395C8.76061 16.2295 9.02061 17.1195 8.86061 17.7895L8.33061 20.0995C8.18061 20.7695 8.28061 21.0995 8.36061 21.1495C8.44061 21.2095 8.79062 21.2095 9.37062 20.8595L11.6106 19.5295C11.9506 19.3395 12.3506 19.2395 12.7506 19.2395Z'
                fill='#544845'
              />
              <path
                d='M6.75 9.75C6.34 9.75 6 9.41 6 9V2C6 1.59 6.34 1.25 6.75 1.25C7.16 1.25 7.5 1.59 7.5 2V9C7.5 9.41 7.16 9.75 6.75 9.75Z'
                fill='#544845'
              />
              <path
                d='M18.75 9.75C18.34 9.75 18 9.41 18 9V2C18 1.59 18.34 1.25 18.75 1.25C19.16 1.25 19.5 1.59 19.5 2V9C19.5 9.41 19.16 9.75 18.75 9.75Z'
                fill='#544845'
              />
              <path
                d='M12.75 4.75C12.34 4.75 12 4.41 12 4V2C12 1.59 12.34 1.25 12.75 1.25C13.16 1.25 13.5 1.59 13.5 2V4C13.5 4.41 13.16 4.75 12.75 4.75Z'
                fill='#544845'
              />
            </svg>
            <p className='common-font'>YouTube Reviews</p>
          </div>
          <Divider
            orientation='vertical'
            variant='middle'
            flexItem
            style={{
              borderColor: 'var(--bownbody)',
              opacity: 0.2,
            }}
          />
          <div className='sub-nav-bar-container-left-div sub-nav-bar-container-right-div'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                d='M4 15V18C4 19.1046 4.89543 20 6 20H7C8.10457 20 9 19.1046 9 18V15C9 13.8954 8.10457 13 7 13H6C4.89543 13 4 13.8954 4 15ZM4 15V12C4 9.87827 4.84285 7.84344 6.34315 6.34315C7.84344 4.84285 9.87827 4 12 4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12L20 15M20 15C20 13.8954 19.1046 13 18 13H17C15.8954 13 15 13.8954 15 15V18C15 19.1046 15.8954 20 17 20H18C19.1046 20 20 19.1046 20 18V15Z'
                stroke='#544845'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
            <p className='common-font'>+92 300 1230326</p>
          </div>
        </div>
      </MaxWidthWrapper>
    </div>
  );
}

export default Subnavbar;
