import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getBrands } from '../Api/ApiUrl';
import { useNavigate } from 'react-router-dom';
import MaxWidthWrapper from '../Components/MaxWidthWrapper';
import '../Css/Components/Footer.css';
import Logo from '../assets/images/logo2.svg';

function Footer() {
  const navigate = useNavigate();
  const [AllBrands, setAllBrands] = useState([]);

  const [hideProducts, sethideProducts] = useState(true);

  useEffect(() => {
    const getProductDetails = async () => {
      try {
        const brands = await axios.get(getBrands);
        console.log('I got data FROM backend', brands?.data?.data);
        setAllBrands(brands?.data?.data);
        console.log('ALL BRANDS', brands?.data?.data);

        sethideProducts(false);
      } catch (err) {
        //console.log(err);
      }
    };
    getProductDetails();
  }, []);
  return (
    <div className='footer-top-container-parent'>
      <MaxWidthWrapper>
        <div className='footer-container'>
          <div className='footer-top-container'>
            <div className='footer-top-container-common footer-top-container-left'>
              <img src={Logo} alt='Logo' className='nav-bar-logo' />
              <p className='common-font footer-top-container-common-font without-pointer'>
                Your premier destination for high-quality laptops and
                exceptional service located at LG 53, Rania Mall Bank Road
                Saddar Rawalpindi.
              </p>
            </div>
            <div className='footer-top-container-common'>
              <p className='common-font footer-top-container-common-heading'>
                Services
              </p>
              <p
                className='common-font footer-top-container-common-font'
                onClick={() => {
                  navigate('/laptop');
                }}
              >
                Laptops
              </p>
              <p
                className='common-font footer-top-container-common-font'
                onClick={() => {
                  navigate('/laptop');
                }}
              >
                Laptops Accessories
              </p>
              <p
                className='common-font footer-top-container-common-font'
                onClick={() => {
                  navigate('/blog');
                }}
              >
                Blogs
              </p>
              <p className='common-font footer-top-container-common-font'>
                Youtube Reviews
              </p>
            </div>
            <div className='footer-top-container-common '>
              <p className='common-font footer-top-container-common-heading'>
                Brands
              </p>
              {hideProducts ? (
                <p>Please Wait</p>
              ) : (
                <div className='footer-top-container-common '>
                  {AllBrands.map((data, index) => {
                    return (
                      <p
                        className='common-font footer-top-container-common-font'
                        key={index}
                        onClick={() => {
                          navigate(
                            `/category/2/brand/${encodeURIComponent(data?.id)}`
                          );
                        }}
                      >
                        {data?.attributes?.name}
                      </p>
                    );
                  })}
                </div>
              )}

              {/* <p className='common-font footer-top-container-common-font'>
                Find an order
              </p>
              <p className='common-font footer-top-container-common-font'>
                Terms of delivery
              </p>
              <p className='common-font footer-top-container-common-font'>
                Exchange and return of goods
              </p>
              <p className='common-font footer-top-container-common-font'>
                Guarantee
              </p>
              <p className='common-font footer-top-container-common-font'>
                Frequently asked questions
              </p>
              <p className='common-font footer-top-container-common-font'>
                Terms of use of the site
              </p> */}
            </div>
            <div className='footer-top-container-common'>
              <p className='common-font footer-top-container-common-heading footer-top-container-common-heading-extra'>
                Accepted Payment
              </p>
              <div className='footer-payment'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='31'
                  height='32'
                  viewBox='0 0 31 32'
                  fill='none'
                >
                  <g clip-path='url(#clip0_3053_2207)'>
                    <path
                      d='M15.017 22.85C13.3637 24.2432 11.27 25.005 9.108 25C4.078 25 0 20.97 0 16C0 11.03 4.078 7.00002 9.108 7.00002C11.363 7.00002 13.426 7.81002 15.017 9.15002C16.67 7.75705 18.7633 6.99526 20.925 7.00002C25.955 7.00002 30.033 11.03 30.033 16C30.033 20.97 25.955 25 20.925 25C18.7633 25.0048 16.67 24.243 15.017 22.85Z'
                      fill='#ED0006'
                    />
                    <path
                      d='M15.017 22.85C16.0186 22.0115 16.8242 20.9636 17.377 19.7801C17.9299 18.5966 18.2166 17.3063 18.217 16C18.217 13.257 16.975 10.8 15.017 9.15002C16.67 7.75705 18.7633 6.99526 20.925 7.00002C25.955 7.00002 30.033 11.03 30.033 16C30.033 20.97 25.955 25 20.925 25C18.7633 25.0048 16.67 24.243 15.017 22.85Z'
                      fill='#F9A000'
                    />
                    <path
                      d='M15.017 22.85C16.975 21.2 18.217 18.743 18.217 16C18.217 13.257 16.975 10.8 15.017 9.15002C14.0154 9.98856 13.2098 11.0365 12.657 12.22C12.1041 13.4035 11.8174 14.6938 11.817 16C11.817 18.743 13.058 21.2 15.017 22.85Z'
                      fill='#FF5E00'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_3053_2207'>
                      <rect width='30.033' height='32' fill='white' />
                    </clipPath>
                  </defs>
                </svg>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='56'
                  height='32'
                  viewBox='0 0 56 32'
                  fill='none'
                >
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M10.191 10L5 21.826H11.215L11.985 19.941H13.746L14.517 21.827H21.357V20.387L21.967 21.827H25.507L26.116 20.357V21.827H40.343L42.073 19.99L43.693 21.827L51 21.842L45.792 15.946L51 10H43.806L42.122 11.803L40.553 10H25.076L23.746 13.053L22.387 10H16.186V11.39L15.496 10H10.193H10.191ZM11.394 11.68H14.424L17.867 19.699V11.679H21.186L23.846 17.429L26.296 11.679H29.6V20.166H27.59L27.574 13.516L24.644 20.166H22.847L19.902 13.516V20.166H15.768L14.985 18.263H10.75L9.968 20.164H7.754L11.394 11.679V11.68ZM39.607 11.68H31.437V20.16H39.48L42.073 17.35L44.572 20.16H47.184L43.387 15.945L47.184 11.679H44.685L42.105 14.458L39.607 11.679V11.68ZM12.867 13.115L11.473 16.505H14.261L12.868 13.115H12.867ZM33.454 14.985V13.435H38.552L40.776 15.912L38.453 18.404H33.454V16.712H37.911V14.986H33.454V14.985Z'
                    fill='#2E1F1C'
                  />
                </svg>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='42'
                  height='32'
                  viewBox='0 0 42 32'
                  fill='none'
                >
                  <path
                    d='M27.482 9.00008C28.5269 8.99548 29.5629 9.19135 30.534 9.57708L30.071 12.5031L29.763 12.3601C28.9701 11.9927 28.1026 11.8147 27.229 11.8401C25.886 11.8401 25.283 12.4311 25.269 13.0081C25.269 13.6431 25.999 14.0611 27.189 14.6811C29.149 15.6471 30.057 16.8291 30.044 18.3711C30.016 21.1841 27.692 23.0001 24.122 23.0001C22.596 22.9851 21.126 22.6531 20.328 22.2791L20.804 19.2361L21.252 19.4531C22.358 19.9581 23.086 20.1731 24.444 20.1731C25.424 20.1731 26.474 19.7551 26.487 18.8481C26.487 18.2561 26.04 17.8241 24.724 17.1601C23.436 16.5111 21.714 15.4301 21.742 13.4841C21.756 10.8451 24.122 9.00008 27.482 9.00008ZM14.798 22.7981H18.2L20.328 9.24608H16.926L14.798 22.7981Z'
                    fill='#00579F'
                  />
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M39.255 9.24608H36.624C35.812 9.24608 35.196 9.49108 34.845 10.3701L29.792 22.7981H33.362L34.076 20.7651H38.444C38.542 21.2411 38.85 22.7981 38.85 22.7981H42L39.255 9.24608ZM35.055 17.9961L36.415 14.2061C36.408 14.2161 36.453 14.0901 36.519 13.9011C36.617 13.6211 36.762 13.2081 36.862 12.9081L37.099 14.0751C37.099 14.0751 37.744 17.3191 37.884 17.9971H35.056L35.055 17.9961Z'
                    fill='#00579F'
                  />
                  <path
                    d='M8.624 18.4871L11.956 9.24608H15.554L10.206 22.7841H6.608L3.556 10.9321C5.712 12.1001 7.644 14.4501 8.26 16.6121L8.624 18.4871Z'
                    fill='#00579F'
                  />
                  <path
                    d='M5.53 9.24608H0.0560002L0 9.51908C4.27 10.6441 7.098 13.3551 8.26 16.6131L7.07 10.3851C6.874 9.51908 6.272 9.27508 5.53 9.24508V9.24608Z'
                    fill='#FAA61A'
                  />
                </svg>
              </div>
            </div>
          </div>

          <div className='footer-bottom-container'>
            <svg
              onClick={() => {
                window.open(
                  'https://x.com/laptopsquarepk?t=eDWTg5EH8LDoGrnwloLR4Q&s=09'
                );
              }}
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
            >
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M16 2.91241C15.4121 3.18014 14.7791 3.36119 14.1152 3.44209C14.7932 3.02605 15.3135 2.36637 15.5586 1.58053C14.9238 1.96574 14.2222 2.24599 13.4728 2.39622C12.8755 1.74231 12.0218 1.33398 11.0771 1.33398C9.26548 1.33398 7.79574 2.84115 7.79574 4.69982C7.79574 4.96369 7.82391 5.21987 7.88026 5.4664C5.15208 5.3258 2.73381 3.98717 1.11381 1.9484C0.831132 2.44726 0.669596 3.02605 0.669596 3.6424C0.669596 4.80961 1.24905 5.84007 2.12995 6.44389C1.59277 6.42752 1.08563 6.2744 0.642365 6.02401V6.06541C0.642365 7.69681 1.77402 9.05759 3.27663 9.36576C3.00147 9.44473 2.71128 9.48422 2.41169 9.48422C2.20039 9.48422 1.99378 9.46399 1.79374 9.42451C2.21166 10.7612 3.42313 11.7349 4.86001 11.7609C3.7368 12.6642 2.32059 13.2025 0.783234 13.2025C0.518398 13.2025 0.256383 13.1871 0 13.1563C1.45284 14.1097 3.17896 14.6673 5.03187 14.6673C11.0705 14.6673 14.3715 9.53911 14.3715 5.09082C14.3715 4.94443 14.3687 4.79805 14.3631 4.65456C15.0045 4.17978 15.5614 3.58751 16 2.91241Z'
                fill='#2E1F1C'
              />
            </svg>
            <svg
              onClick={() => {
                window.open(
                  'https://www.facebook.com/laptopsquarepk?mibextid=ZbWKwL'
                );
              }}
              xmlns='http://www.w3.org/2000/svg'
              width='17'
              height='16'
              viewBox='0 0 17 16'
              fill='none'
            >
              <path
                d='M9.18106 3.81794C9.18106 3.98169 9.18106 4.51541 9.18106 5.27366H11.7881L11.5054 7.59763H9.18106C9.18106 11.1816 9.18106 15.9843 9.18106 15.9843H6.09587C6.09587 15.9843 6.09587 11.2452 6.09587 7.59763H4.48828V5.27366H6.09587C6.09587 4.35204 6.09587 3.69669 6.09587 3.51422C6.09587 2.64485 6.03037 2.23235 6.39978 1.55866C6.76937 0.885005 7.81187 -0.00543266 9.61497 0.0160048C11.4185 0.0382236 12.1787 0.211724 12.1787 0.211724L11.7881 2.6885C11.7881 2.6885 10.6364 2.38441 10.0711 2.49279C9.50659 2.60119 9.18106 2.94894 9.18106 3.81794Z'
                fill='#2E1F1C'
              />
            </svg>
            <svg
              onClick={() => {
                window.open('/');
              }}
              xmlns='http://www.w3.org/2000/svg'
              width='17'
              height='16'
              viewBox='0 0 17 16'
              fill='none'
            >
              <path
                d='M12.5494 3.358C11.89 2.59144 11.5446 1.60104 11.5721 0.592723L9.10827 0.533203C9.10827 0.533203 9.10827 0.638323 9.10827 0.780083V10.6602C9.14267 13.786 4.20411 13.7102 4.59483 10.2551C4.83371 8.88136 6.30987 8.03592 7.62155 8.51384V5.99992C4.76587 5.50264 2.05963 7.7756 2.08811 10.6713C2.33771 16.9513 11.3451 16.9521 11.595 10.6713C11.5321 10.4465 11.5673 6.26152 11.5584 5.90856C12.6787 6.60568 13.9822 6.95416 15.3024 6.90952V4.3132C14.0819 4.3132 13.1505 3.98872 12.5494 3.358Z'
                fill='#2E1F1C'
              />
            </svg>

            <svg
              onClick={() => {
                window.open('https://youtube.com/@laptopsquarepk');
              }}
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 50 50'
              width='19px'
              height='19px'
            >
              <path d='M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z' />
            </svg>
          </div>
        </div>
      </MaxWidthWrapper>
    </div>
  );
}

export default Footer;
