import React from 'react';
import '../../Css/LaptopDetailsScreen/LaptopTextualDetails.css';

import MaxWidthWrapper from '../../Components/MaxWidthWrapper';
import Buttons from '../../Components/Buttons/Buttons';

import Paragraphs from '../../Components/Buttons/Paragraphs';
function LaptopTextualDetails({ productDetails }) {
  const screendetails = [
    {
      title: 'Brand',
      description:
        productDetails?.brand == null ? 'N/A' : productDetails?.brand,
    },
    {
      title: 'Series',
      description:
        productDetails?.series == null ? 'N/A' : productDetails?.series,
    },
    {
      title: 'Screen Size',
      description:
        productDetails?.product_information?.screensize == null
          ? 'N/A'
          : productDetails?.product_information?.screensize,
    },
    {
      title: 'Screen Resolution',
      description:
        productDetails?.product_information?.screenreso == null
          ? 'N/A'
          : productDetails?.product_information?.screenreso,
    },
    {
      title: 'Touchscreen',
      description:
        productDetails?.product_information?.touchscreen == null
          ? 'N/A'
          : productDetails?.product_information?.touchscreen,
    },
    {
      title: 'Operating System',
      description:
        productDetails?.product_information?.os == null
          ? 'N/A'
          : productDetails?.product_information?.os,
    },
    {
      title: 'Generation',
      description:
        productDetails?.product_information?.generation == null
          ? 'N/A'
          : productDetails?.product_information?.generation,
    },
    {
      title: 'Processor Type',
      description:
        productDetails?.product_information?.ptype == null
          ? 'N/A'
          : productDetails?.product_information?.ptype,
    },
    {
      title: 'Processor Model',
      description:
        productDetails?.product_information?.pmodel == null
          ? 'N/A'
          : productDetails?.product_information?.pmodel,
    },
    {
      title: 'Processor Speed',
      description:
        productDetails?.product_information?.pspeed == null
          ? 'N/A'
          : productDetails?.product_information?.pspeed,
    },
    {
      title: 'Processor Turbo',
      description:
        productDetails?.product_information?.pturbo == null
          ? 'N/A'
          : productDetails?.product_information?.pturbo,
    },
    {
      title: 'Processor Cache',
      description:
        productDetails?.product_information?.pcache == null
          ? 'N/A'
          : productDetails?.product_information?.pcache,
    },
    {
      title: 'RAM',
      description:
        productDetails?.product_information?.ram == null
          ? 'N/A'
          : productDetails?.product_information?.ram,
    },
  ];

  console.log('Laptoppp', productDetails);
  return (
    <div className='laptop-textual-details-top-div'>
      <div className='laptop-textual-details-inner-div'>
        <MaxWidthWrapper>
          <div className='laptop-textual-details-div'>
            <p className='common-font laptop-textual-details-div-top-heading'>
              Details
            </p>
            <p className='common-font laptop-textual-details-div-top-details'>
              {productDetails?.specs}
            </p>
            {productDetails?.category === 'Laptop' && (
              <div>
                <p className='common-font laptop-textual-details-screen-text'>
                  Screen
                </p>

                <div className='laptop-textual-details-screen-under-div'>
                  {screendetails.map((data) => {
                    return (
                      <div className='laptop-textual-details-screen-under-inner-div'>
                        <p className='common-font laptop-textual-details-screen-under-inner-div-text1'>
                          {data?.title}
                        </p>
                        <p className='common-font laptop-textual-details-screen-under-inner-div-text2'>
                          {data?.description}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {/* <div className='laptop-textual-details-button-div'>
              <Buttons
                size='large'
                variant='outlined1'
                border='have-border'
                className='buttons-extra'
              >
                <Paragraphs
                  size='plarge'
                  variant={`poutlined1`}
                  border='phave-border'
                >
                  View More{' '}
                </Paragraphs>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <path
                    d='M18 9L12 15L6 9'
                    stroke='#2E1F1C'
                    stroke-width='1.5'
                    stroke-linecap='round'
                  />
                </svg>
              </Buttons>
            </div> */}
          </div>
        </MaxWidthWrapper>
      </div>
    </div>
  );
}

export default LaptopTextualDetails;
