import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';

import loader from '../../assets/Lottie/loader.json';
import { getSingleProduct } from '../../Api/ApiUrl';
//mui breadcrum

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import '../../Css/LaptopDetailsScreen/LaptopDetailsScreen.css';
import MaxWidthWrapper from '../../Components/MaxWidthWrapper';
import BreadcrumbsComponent from '../../Components/BreadcrumbsComponent';
import LaptopDetailsTop from '../../Modules/LaptopDetailsModule/LaptopDetailsTop';
import LaptopTextualDetails from '../../Modules/LaptopDetailsModule/LaptopTextualDetails';
import Discount from '../../Modules/LandingPageodules/Discount';
import ReleatedProducts from '../../Modules/LaptopDetailsModule/ReleatedProducts';

import { useParams } from 'react-router-dom';

function LaptopDetailsScreen() {
  //getting cid from params
  const { params: id } = useParams();

  //Breadcrumb

  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.', event.target);
  }

  //States
  const [productDetails, setproductDetails] = useState([]);
  const [breadcrumbs, setbreadcrumbs] = useState([]);
  const [hideProducts, sethideProducts] = useState(true);

  useEffect(() => {
    const getProductDetails = async () => {
      try {
        const productid = new FormData();

        productid.append('id', id);
        const productDetails = await axios.post(getSingleProduct, productid);
        console.log('I got data FROM backend', productDetails?.data?.data);
        setproductDetails(productDetails?.data?.data[0]);

        setbreadcrumbs([
          <Link
            underline='hover'
            key='1'
            color='inherit'
            href='/'
            // onClick={handleClick}
            className='common-font'
          >
            Home
          </Link>,
          <Link
            underline='hover'
            key='2'
            color='inherit'
            href='/'
            // onClick={handleClick}
            className='common-font'
          >
            {productDetails?.data?.data[0]?.category}
          </Link>,
          <Link
            underline='hover'
            key='2'
            color='inherit'
            //   href='/material-ui/getting-started/installation/'
            onClick={handleClick}
            className='common-font'
          >
            {productDetails?.data?.data[0]?.brand}
          </Link>,

          <Typography key='3' color='text.primary' className='common-font'>
            {productDetails?.data?.data[0]?.product_information?.name}
          </Typography>,
        ]);

        sethideProducts(false);
      } catch (err) {
        //console.log(err);
      }
    };
    getProductDetails();
  }, [id]);

  if (hideProducts) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Lottie
          animationData={loader}
          autoplay={true}
          loop={true}
          style={{ width: '300px' }}
        />
      </div>
    );
  }
  return (
    <div>
      <MaxWidthWrapper>
        <div className='laptop-screen-div'>
          <BreadcrumbsComponent breadcrumbs={breadcrumbs} />
        </div>

        <div className='laptop-details-top-container'>
          <LaptopDetailsTop productDetails={productDetails} />
        </div>
      </MaxWidthWrapper>

      <LaptopTextualDetails productDetails={productDetails} />

      <ReleatedProducts />
    </div>
  );
}

export default LaptopDetailsScreen;
