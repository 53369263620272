import React, { useState, useEffect } from 'react';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import lottiePath from '../../assets/Lottie/Empty box.json';
import { useLocation } from 'react-router-dom';
import loader from '../../assets/Lottie/loader.json';
import { useParams } from 'react-router-dom';
import ProductComponent from '../../Components/ProductComponent';
import axios from 'axios';
import { search } from '../../Api/ApiUrl';
import MaxWidthWrapper from '../../Components/MaxWidthWrapper';
import { Button, Pagination } from '@mui/material';
const SearchScreen = () => {
  const location = useLocation();
  const [products, setAllproducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hideProducts, sethideProducts] = useState(true);
  const [currentProducts, setcurrentProducts] = useState([]);

  var itemsPerPage = 12;

  useEffect(() => {
    console.log('Again search happens');
    const searching = async () => {
      const userCredentials = new FormData();

      userCredentials.append('query', location?.state?.searchString);

      try {
        const getAllProducts = await axios.post(search, userCredentials);
        setAllproducts(getAllProducts?.data?.data);

        //Pagination

        // Calculate the products to display for the current page
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setcurrentProducts(
          getAllProducts?.data?.data.slice(indexOfFirstItem, indexOfLastItem)
        );
        sethideProducts(false);
      } catch (err) {
        //console.log(err.response);
      }
    };

    searching();
  }, [location?.state?.searchString]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    const indexOfLastItem = value * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    setcurrentProducts(products.slice(indexOfFirstItem, indexOfLastItem));
  };
  if (hideProducts) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Lottie
          animationData={loader}
          autoplay={true}
          loop={true}
          style={{ width: '300px' }}
        />
      </div>
    );
  }

  return (
    <div>
      <MaxWidthWrapper>
        <div className='laptop-screen-div'>
          {/* <BreadcrumbsComponent breadcrumbs={breadcrumbs} /> */}

          <div className='laptop-bottom-div'>
            {/* right side */}
            <div className='laptop-right-div' style={{ flex: 1 }}>
              <div className='laptop-right-div-top-div'>
                <p className='common-font laptop-total-products'>
                  Products found: <span>{products.length}</span>
                </p>
              </div>
              <div>
                {currentProducts.length > 0 ? (
                  <div className='products-bottom-parent-container'>
                    {currentProducts.map((data, index) => {
                      console.log('i am inside current product');
                      return (
                        <ProductComponent
                          key={index}
                          img={data?.product_information?.images[0]}
                          title={data?.product_information?.name}
                          price={data?.product_information?.price}
                          id={data?.product_uuid}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 0,
                      width: '100%',
                      alignItems: 'center',
                    }}
                  >
                    <Lottie
                      animationData={lottiePath}
                      autoplay={true}
                      loop={true}
                      style={{ width: '40%' }}
                    />
                    <p className='common-font laptop-total-products'>
                      No product found :(
                    </p>
                  </div>
                )}

                <div className='laptop-pagination-div '>
                  {currentProducts.length > 0 && (
                    <Pagination
                      count={Math.ceil(products.length / itemsPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                      shape='rounded'
                      sx={{
                        '& .MuiPaginationItem-root': {
                          color: '#2E1F1C !important',
                        },
                        '& .Mui-selected': {
                          backgroundColor: '#2E1F1C !important',
                          color: '#fff !important ',
                        },
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MaxWidthWrapper>
    </div>
  );
};

export default SearchScreen;
