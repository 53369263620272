import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import { IoMenu as MenuIcon } from 'react-icons/io5';
import { FiSearch as SearchIcon } from 'react-icons/fi';
import { LuUser as AccountCircle } from 'react-icons/lu';
import { CiHeart as MailIcon } from 'react-icons/ci';
import { IoCartOutline as NotificationsIcon } from 'react-icons/io5';
import { RiMore2Fill as MoreIcon } from 'react-icons/ri';
import MaxWidthWrapper from './MaxWidthWrapper';
import { StyledEngineProvider } from '@mui/material';
import Logo from '../assets/images/logo.svg';

import '../Css/Components/Navbar.css';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default function Navbar() {
  const location = useLocation();
  const path = location.pathname.split('/').pop();

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [activetab, setactivetab] = useState(path ? path : 'Home');
  const [lastScreen, setlastScreen] = useState('');

  const SearchFunction = async (e) => {
    if (location.pathname !== '/search') {
      // console.log('Ya ha path', location.pathname);
      // const parts = location.pathname.split('/');
      // console.log('Ya ha Parts', parts);
      // const base = parts.length > 1 ? `/${parts[1]}` : location.pathname;
      // console.log('Ya ha base', base);

      setlastScreen(location.pathname);
    }
    if (e.target.value.length < 2) {
      if (location.pathname === '/search') {
        navigate(lastScreen);
      }
    } else {
      const searchString = e.target.value;
      navigate('/search', {
        state: { searchString },
      });
    }
  };

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size='large' aria-label='show 4 new mails' color='inherit'>
          <Badge badgeContent={4} color='error'>
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size='large'
          aria-label='show 17 new notifications'
          color='inherit'
        >
          <Badge badgeContent={17} color='error'>
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size='large'
          aria-label='account of current user'
          aria-controls='primary-search-account-menu'
          aria-haspopup='true'
          color='inherit'
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <StyledEngineProvider injectFirst>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position='fixed'
          style={{
            backgroundColor: 'var(--white)',
            height: 88,
            justifyContent: 'center',
          }}
        >
          <MaxWidthWrapper>
            <Toolbar className='nav-bar-padding'>
              {/* <IconButton
              size='large'
              edge='start'
              color='inherit'
              aria-label='open drawer'
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton> */}
              <img
                src={Logo}
                alt='Logo'
                className='nav-bar-logo'
                onClick={() => {
                  navigate('/');
                }}
              />
              {/* <Typography
              variant='h6'
              noWrap
              component='div'
              sx={{ display: { xs: 'none', sm: 'block' } }}
            >
              MUI
            </Typography> */}
              <Search className='search-navbar'>
                <SearchIconWrapper>
                  <SearchIcon color='var(--disabledgrey)' size={24} />
                </SearchIconWrapper>
                <StyledInputBase
                  className='search-bar-nav-bar'
                  placeholder='Search…'
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={(e) => {
                    SearchFunction(e);
                  }}
                />
              </Search>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <Button
                  className={
                    activetab === 'Home'
                      ? `common-font nav-bar-button nav-bar-button-active`
                      : `common-font nav-bar-button `
                  }
                  // key={page}
                  // onClick={handleCloseNavMenu}
                  onClick={() => {
                    setactivetab('Home');
                    navigate('/');
                  }}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  Home
                </Button>
                <Button
                  className={
                    activetab === 'about-us'
                      ? `common-font nav-bar-button nav-bar-button-active`
                      : `common-font nav-bar-button `
                  }
                  // key={page}
                  onClick={() => {
                    setactivetab('about-us');
                    navigate('/about-us');
                  }}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  About
                </Button>
                <Button
                  className={
                    activetab === 'contact-us'
                      ? `common-font nav-bar-button nav-bar-button-active`
                      : `common-font nav-bar-button `
                  }
                  // key={page}
                  onClick={() => {
                    setactivetab('contact-us');
                    navigate('/contact-us');
                  }}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  Contact Us
                </Button>

                <Button
                  className={
                    activetab === 'blog'
                      ? `common-font nav-bar-button nav-bar-button-active`
                      : `common-font nav-bar-button `
                  }
                  // key={page}
                  // onClick={handleCloseNavMenu}
                  onClick={() => {
                    setactivetab('blog');
                    navigate('/blog');
                  }}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  Blog
                </Button>
              </Box>
              {/* <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <IconButton
                  size='large'
                  aria-label='show 4 new mails'
                  color='black'
                >
                  <MailIcon />
                </IconButton>
                <IconButton
                  size='large'
                  aria-label='show 17 new notifications'
                  color='black'
                >
                  <NotificationsIcon />
                </IconButton>
                <IconButton
                  size='large'
                  edge='end'
                  aria-label='account of current user'
                  aria-controls={menuId}
                  aria-haspopup='true'
                  onClick={handleProfileMenuOpen}
                  color='black'
                >
                  <AccountCircle />
                </IconButton>
              </Box> */}
              {/* <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size='large'
                  aria-label='show more'
                  aria-controls={mobileMenuId}
                  aria-haspopup='true'
                  onClick={handleMobileMenuOpen}
                  color='black'
                >
                  <MoreIcon />
                </IconButton>
              </Box> */}
            </Toolbar>
          </MaxWidthWrapper>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Box>
    </StyledEngineProvider>
  );
}
