import React from 'react';

//importing modules
import HeroSection from '../../Modules/LandingPageodules/HeroSection';
import SubHeroSection from '../../Modules/LandingPageodules/SubHeroSection';
import Categories from '../../Modules/LandingPageodules/Categories';
import Products from '../../Modules/LandingPageodules/Products';
import Discount from '../../Modules/LandingPageodules/Discount';
import FeaturedLaptop from '../../Modules/LandingPageodules/FeaturedLaptop';
import Banner from '../../Modules/LandingPageodules/Banner';

import AccordionLanding from '../../Modules/LandingPageodules/AccordionLanding';

function LandingPage() {
  return (
    <>
      <HeroSection />
      {/* <SubHeroSection /> */}
      <Categories />
      <Products />
      {/* <FeaturedLaptop /> */}
      {/* <Discount /> */}
      {/* <Banner /> */}
      <AccordionLanding />
    </>
  );
}

export default LandingPage;
