import React, { useRef, useEffect, useState } from 'react';
import { NavLink, Outlet, Link } from 'react-router-dom';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import '../../Css/Layout/Layout.css';
import Subnavbar from '../../Modules/LandingPageodules/Subnavbar';
import whatsapp from '../../assets/images/whatsapp.svg';

function Layout() {
  const footerRef = useRef(null);
  const [footerHeight, setFooterHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (footerRef.current) {
        setFooterHeight(footerRef.current.offsetHeight);
      }
    };

    handleResize(); // Set initial height
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div style={{ position: 'relative', minHeight: 'calc(100vh - 88px)' }}>
      <div>
        <Navbar />
      </div>
      {/* <div style={{ marginTop: 88, paddingBottom: footerHeight }}> */}
      <div style={{ marginTop: 88 }}>
        <div className='subnavbar-div'>
          <Subnavbar />
        </div>
        <div
          style={{
            position: 'fixed',
            bottom: 40,
            right: 40,
            zIndex: 100,
            cursor: 'pointer',
          }}
          onClick={() => {
            const phoneNumber = '923001230326';
            const text = 'How may I help you';
            const whatsappLink = `//api.whatsapp.com/send?phone=${phoneNumber}&text=${text}`;
            window.open(whatsappLink, '_blank');
          }}
        >
          <img src={whatsapp} alt='' width={82} />
        </div>

        <Outlet />
      </div>
      <div ref={footerRef} style={{ position: 'sticky', width: '100%' }}>
        <Footer />
      </div>
    </div>
  );
}

export default Layout;
