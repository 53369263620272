import React, { useState, useEffect } from 'react';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import lottiePath from '../../assets/Lottie/Empty box.json';
import loader from '../../assets/Lottie/loader.json';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  getBrands,
  getCategoryProducts,
  getBrandProducts,
} from '../../Api/ApiUrl';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';

import { IoIosArrowDown as ExpandMoreIcon } from 'react-icons/io';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button, Pagination } from '@mui/material';
// Slider
import Slider from '@mui/material/Slider';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { MdNavigateNext as NavigateNextIcon } from 'react-icons/md';

import MaxWidthWrapper from '../../Components/MaxWidthWrapper';

import '../../Css/LaptopScreen/LaptopScreen.css';
import Paragraphs from '../../Components/Buttons/Paragraphs';
import Buttons from '../../Components/Buttons/Buttons';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { FiSearch as SearchIcon } from 'react-icons/fi';
import ProductComponent from '../../Components/ProductComponent';
// importing images
import product1 from '../../assets/images/product1.png';
import BreadcrumbsComponent from '../../Components/BreadcrumbsComponent';
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));
function LaptopScreen() {
  const navigate = useNavigate();
  const { cid } = useParams();
  const { bid } = useParams();
  const [products, setAllproducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hideProducts, sethideProducts] = useState(true);
  const [currentProducts, setcurrentProducts] = useState([]);
  const [AllBrands, setAllBrands] = useState([]);
  console.log('THe id i got is ', cid);
  var itemsPerPage = 12;
  //get All products from backend
  useEffect(() => {
    const getAllProducts = async (url, bid, key) => {
      try {
        const productid = new FormData();

        productid.append(key, bid);
        const getAllProducts = await axios.post(url, productid);
        console.log('I got data FROM backend', getAllProducts?.data?.data);
        setAllproducts(getAllProducts?.data?.data);

        //Pagination

        // Calculate the products to display for the current page
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setcurrentProducts(
          getAllProducts?.data?.data.slice(indexOfFirstItem, indexOfLastItem)
        );
        sethideProducts(false);
      } catch (err) {
        //console.log(err);
      }
    };
    if (cid && bid) {
      getAllProducts(getBrandProducts, bid, 'brand');
    } else {
      getAllProducts(getCategoryProducts, cid, 'category');
    }

    const getProductDetails = async () => {
      try {
        const brands = await axios.get(getBrands);
        console.log('I got data FROM backend', brands?.data?.data);
        setAllBrands(brands?.data?.data);
        console.log('ALL BRANDS', brands?.data?.data);

        sethideProducts(false);
      } catch (err) {
        //console.log(err);
      }
    };
    getProductDetails();
  }, [cid, bid]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    const indexOfLastItem = value * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    setcurrentProducts(products.slice(indexOfFirstItem, indexOfLastItem));
  };

  // slider function
  function valuetext(value) {
    return `${value}°C`;
  }

  const minDistance = 1000;

  // Bread crumb functions
  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }
  var breadcrumbs;
  if (cid && bid) {
    console.log(cid, 'cid and bid', bid);
    breadcrumbs = [
      <Link
        underline='hover'
        key='1'
        color='inherit'
        href='/'
        onClick={handleClick}
        className='common-font'
      >
        Home
      </Link>,
      <Link
        underline='hover'
        key='2'
        color='inherit'
        href='/'
        onClick={handleClick}
        className='common-font'
      >
        {cid == 2
          ? 'Laptop'
          : cid == 3
          ? 'Game Accessories'
          : cid == 5
          ? 'Keyboards'
          : cid == 6
          ? 'Mouse'
          : cid == 7
          ? 'Hard Drive'
          : cid == 8
          ? 'Charger'
          : cid == 9
          ? 'Headsets'
          : 'Undefined'}
      </Link>,
      <Typography key='3' color='text.primary' className='common-font'>
        {bid == 2
          ? 'HP'
          : bid == 3
          ? 'DELL'
          : bid == 4
          ? 'Apple'
          : bid == 5
          ? 'Acer'
          : bid == 6
          ? 'MSI'
          : bid == 7
          ? 'Asus'
          : bid == 8
          ? 'lenovo'
          : bid == 9
          ? 'Microsoft'
          : bid == 10
          ? 'Razor Blade'
          :bid == 11?"ALTEC": 'Undefined'}
      </Typography>,
    ];
  } else {
    console.log(cid === 2, 'cid ');
    breadcrumbs = [
      <Link
        underline='hover'
        key='1'
        color='inherit'
        href='/'
        onClick={handleClick}
        className='common-font'
      >
        Home
      </Link>,

      <Typography key='3' color='text.primary' className='common-font'>
        {cid == 2
          ? 'Laptop'
          : cid == 3
          ? 'Game Accessories'
          : cid == 5
          ? 'Keyboards'
          : cid == 6
          ? 'Mouse'
          : cid == 7
          ? 'Hard Drive'
          : cid == 8
          ? 'Charger'
          : cid == 9
          ? 'Headsets'
          : 'Undefined'}
      </Typography>,
    ];
  }

  //   States

  const [value1, setValue1] = React.useState([100, 800000]);

  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    console.log(value1);
    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
    }
  };

  const currencies = [
    {
      value: 'Islamabad',
      label: 'Islamabad',
    },
    {
      value: 'Lahore',
      label: 'Lahore',
    },
    {
      value: 'Karachi',
      label: 'Karachi',
    },
  ];

  //   categories for laptops

  const categoriesBrand = [
    {
      name: 'Brand',
      list: [
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Acer',
          quantity: 10,
        },
        {
          name: 'Samsung',
          quantity: 110,
        },
        {
          name: 'Dell',
          quantity: 110,
        },
        {
          name: 'Hp',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
      ],
    },
  ];

  const categories = [
    {
      name: 'Brand',
      list: [
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Acer',
          quantity: 10,
        },
        {
          name: 'Samsung',
          quantity: 110,
        },
        {
          name: 'Dell',
          quantity: 110,
        },
        {
          name: 'Hp',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
      ],
    },
    {
      name: 'Series',
      list: [
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Acer',
          quantity: 10,
        },
        {
          name: 'Samsung',
          quantity: 110,
        },
        {
          name: 'Dell',
          quantity: 110,
        },
        {
          name: 'Hp',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
      ],
    },
    {
      name: 'Backlit Keyboard',
      list: [
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Acer',
          quantity: 10,
        },
        {
          name: 'Samsung',
          quantity: 110,
        },
        {
          name: 'Dell',
          quantity: 110,
        },
        {
          name: 'Hp',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
      ],
    },
    {
      name: 'Convertible',
      list: [
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Acer',
          quantity: 10,
        },
        {
          name: 'Samsung',
          quantity: 110,
        },
        {
          name: 'Dell',
          quantity: 110,
        },
        {
          name: 'Hp',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
      ],
    },

    {
      name: 'Generation',
      list: [
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Acer',
          quantity: 10,
        },
        {
          name: 'Samsung',
          quantity: 110,
        },
        {
          name: 'Dell',
          quantity: 110,
        },
        {
          name: 'Hp',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
      ],
    },
    {
      name: 'Processor Type',
      list: [
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Acer',
          quantity: 10,
        },
        {
          name: 'Samsung',
          quantity: 110,
        },
        {
          name: 'Dell',
          quantity: 110,
        },
        {
          name: 'Hp',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
      ],
    },
    {
      name: 'Ram',
      list: [
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Acer',
          quantity: 10,
        },
        {
          name: 'Samsung',
          quantity: 110,
        },
        {
          name: 'Dell',
          quantity: 110,
        },
        {
          name: 'Hp',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
      ],
    },
    {
      name: 'Screen Size',
      list: [
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Acer',
          quantity: 10,
        },
        {
          name: 'Samsung',
          quantity: 110,
        },
        {
          name: 'Dell',
          quantity: 110,
        },
        {
          name: 'Hp',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
      ],
    },

    {
      name: 'Warranty',
      list: [
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Acer',
          quantity: 10,
        },
        {
          name: 'Samsung',
          quantity: 110,
        },
        {
          name: 'Dell',
          quantity: 110,
        },
        {
          name: 'Hp',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
      ],
    },
    {
      name: 'Hard Disk Capacity',
      list: [
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Acer',
          quantity: 10,
        },
        {
          name: 'Samsung',
          quantity: 110,
        },
        {
          name: 'Dell',
          quantity: 110,
        },
        {
          name: 'Hp',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
      ],
    },
    {
      name: 'Graphics',
      list: [
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Acer',
          quantity: 10,
        },
        {
          name: 'Samsung',
          quantity: 110,
        },
        {
          name: 'Dell',
          quantity: 110,
        },
        {
          name: 'Hp',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
      ],
    },
    {
      name: 'Video Memory',
      list: [
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Acer',
          quantity: 10,
        },
        {
          name: 'Samsung',
          quantity: 110,
        },
        {
          name: 'Dell',
          quantity: 110,
        },
        {
          name: 'Hp',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
        {
          name: 'Apple',
          quantity: 110,
        },
      ],
    },
  ];

  console.log(currentProducts, 'Ya ha sab current product');
  if (hideProducts) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Lottie
          animationData={loader}
          autoplay={true}
          loop={true}
          style={{ width: '300px' }}
        />
      </div>
    );
  }
  return (
    <div>
      <MaxWidthWrapper>
        <div className='laptop-screen-div'>
          <BreadcrumbsComponent breadcrumbs={breadcrumbs} />

          <div className='laptop-bottom-div'>
            {/* left side */}
            <div className='laptop-left-div'>
              <Accordion defaultExpanded className='laptop-accordion-div'>
                <AccordionSummary
                  className='common-font laptop-accordion-heading-div'
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1-content'
                  id='panel1-header'
                >
                  Price
                </AccordionSummary>
                <div className='laptop-accordion-details-div'>
                  <div className='laptop-accordion-details-inner-div'>
                    <div className='laptop-input-fields-div'>
                      <div className='laptop-input-fields-left-div'>
                        <label
                          className='common-font laptop-common-label'
                          htmlFor='price'
                        >
                          From
                        </label>
                        <TextField
                          className='contact-us-common-fiel-design'
                          id='outlined-basic'
                          variant='outlined'
                          placeholder='pat@shuffle.dev'
                          value={value1[0]}
                        />
                      </div>

                      <div className='laptop-input-fields-right-div'>
                        <label
                          className='common-font laptop-common-label'
                          htmlFor='price'
                        >
                          To
                        </label>
                        <TextField
                          className='contact-us-common-fiel-design'
                          id='outlined-basic'
                          variant='outlined'
                          value={value1[1]}
                        />
                      </div>
                    </div>
                    <div>
                      <Slider
                        getAriaLabel={() => 'Minimum distance'}
                        value={value1}
                        onChange={handleChange1}
                        valueLabelDisplay='auto'
                        getAriaValueText={valuetext}
                        color='var(--slider)'
                        disableSwap
                        min={0}
                        max={1000000}
                      />
                    </div>
                    <div className='laptop-filter-button-div'>
                      <p className='common-font'>Apply filter</p>
                      <Buttons
                        size='xsmall'
                        variant='contained'
                        border='have-border'
                      >
                        <Paragraphs
                          size='psmall'
                          variant='pcontained'
                          border='phave-border'
                        >
                          OK
                        </Paragraphs>
                      </Buttons>
                    </div>
                  </div>
                </div>
              </Accordion>

              {/* Other categories */}
              {categoriesBrand.map((data, index) => {
                return (
                  <Accordion className='laptop-accordion-div' key={index}>
                    <AccordionSummary
                      className='common-font laptop-accordion-heading-div'
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel1-content'
                      id='panel1-header'
                    >
                      {data?.name}
                    </AccordionSummary>
                    <div className='laptop-accordion-details-div'>
                      <div className='laptop-accordion-details-inner-div'>
                        <div className='laptop-search-bar-div laptop-search-bar'>
                          <Search className='search-navbar laptop-search-bar'>
                            <SearchIconWrapper>
                              <SearchIcon
                                color='var(--disabledgrey)'
                                size={24}
                              />
                            </SearchIconWrapper>
                            <StyledInputBase
                              className='search-bar-nav-bar'
                              placeholder='Search…'
                              inputProps={{ 'aria-label': 'search' }}
                            />
                          </Search>
                        </div>

                        <div className='category-overflow-control-div'>
                          {AllBrands.map((data, index) => {
                            return (
                              <div key={index}>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        onClick={(event) => {
                                          console.log(
                                            'Check box ',
                                            event.target.checked
                                          );
                                          if (event.target.checked) {
                                            navigate(
                                              `/category/2/brand/${encodeURIComponent(
                                                data?.id
                                              )}`
                                            );
                                          }
                                        }}
                                        style={{
                                          color: 'var(--blackmiple)',
                                        }}
                                      />
                                    }
                                    label={
                                      <>
                                        <p className='common-font category-label-text'>
                                          {data?.attributes?.name}{' '}
                                          <span className='category-label-subtext'>
                                            {data?.attributes?.product_count}
                                          </span>{' '}
                                        </p>
                                      </>
                                    }
                                  />
                                </FormGroup>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </Accordion>
                );
              })}
              {/* <Accordion className='laptop-accordion-div'>
                <AccordionSummary
                  className='common-font laptop-accordion-heading-div'
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1-content'
                  id='panel1-header'
                >
                  Brand
                </AccordionSummary>
                <div className='laptop-accordion-details-div'>
                  <div className='laptop-accordion-details-inner-div'>
                 
                    <div className='laptop-search-bar-div laptop-search-bar'>
                      <Search className='search-navbar laptop-search-bar'>
                        <SearchIconWrapper>
                          <SearchIcon color='var(--disabledgrey)' size={24} />
                        </SearchIconWrapper>
                        <StyledInputBase
                          className='search-bar-nav-bar'
                          placeholder='Search…'
                          inputProps={{ 'aria-label': 'search' }}
                        />
                      </Search>
                    </div>
                 
                    <div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked
                              style={{
                                color: 'var(--blackmiple)',
                              }}
                            />
                          }
                          label='Apple'
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </Accordion> */}
            </div>

            {/* right side */}
            <div className='laptop-right-div'>
              <div className='laptop-right-div-top-div'>
                <p className='common-font laptop-total-products'>
                  Products found: <span>{products.length}</span>
                </p>
                {/* <div className='laptop-select-position'>
                  <TextField
                    className='laptop-select-position-input'
                    id='outlined-select-currency'
                    select
                    label='Sorted by position'
                    defaultValue=''
                  >
                    {currencies.map((option, index) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div> */}
              </div>
              <div>
                {currentProducts.length > 0 ? (
                  <div className='products-bottom-parent-container'>
                    {currentProducts.map((data, index) => {
                      console.log('i am inside current product');
                      return (
                        <ProductComponent
                          key={index}
                          img={data?.product_information?.images[0]}
                          title={data?.product_information?.name}
                          price={data?.product_information?.price}
                          id={data?.product_uuid}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 0,
                      width: '100%',
                      alignItems: 'center',
                    }}
                  >
                    <Lottie
                      animationData={lottiePath}
                      autoplay={true}
                      loop={true}
                      style={{ width: '40%' }}
                    />
                    <p className='common-font laptop-total-products'>
                      No product found :(
                    </p>
                  </div>
                )}

                <div className='laptop-pagination-div '>
                  {currentProducts.length > 0 && (
                    <Pagination
                      count={Math.ceil(products.length / itemsPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                      shape='rounded'
                      sx={{
                        '& .MuiPaginationItem-root': {
                          color: '#2E1F1C !important',
                        },
                        '& .Mui-selected': {
                          backgroundColor: '#2E1F1C !important',
                          color: '#fff !important ',
                        },
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MaxWidthWrapper>
    </div>
  );
}

export default LaptopScreen;
