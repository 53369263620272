import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import Stack from '@mui/material/Stack';
import { MdNavigateNext as NavigateNextIcon } from 'react-icons/md';
function BreadcrumbsComponent({ breadcrumbs }) {
  console.log('Ya ha ', breadcrumbs);
  return (
    <div>
      <div className='laptop-screen-div'>
        <div className='laptop-top-div'>
          <Stack spacing={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize='small' />}
              aria-label='breadcrumb'
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
        </div>
      </div>
    </div>
  );
}

export default BreadcrumbsComponent;
