import React from 'react';
import '../../App.css';
import '../../Css/Components/Paragraphs.css';
function Paragraphs({ children, variant, size, border }) {
  return (
    <a className={`common-font ${variant} ${size} ${border}`}>{children}</a>
  );
}

export default Paragraphs;
