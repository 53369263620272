import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { contactus } from '../../Api/ApiUrl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import mail from '../../assets/icons/mail.png';
import phone from '../../assets/icons/phone.png';
import location from '../../assets/icons/location.png';
import social from '../../assets/icons/social.png';

import Facebook from '../../assets/icons/Facebook.svg';
import Twitter from '../../assets/icons/Twitter.svg';
import Instagram from '../../assets/icons/Instagram.svg';
import LinkedIn from '../../assets/icons/LinkedIn.svg';

import MaxWidthWrapper from '../../Components/MaxWidthWrapper';
import TextField from '@mui/material/TextField';

import Buttons from '../../Components/Buttons/Buttons';
import Paragraphs from '../../Components/Buttons/Paragraphs';

import '../../Css/ContactUs/ContactUs.css';
function ContactUs() {
  // Validation of contact us form starts here
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    contact: '',
    message: '',
  });
  const [formErrors, setFormErrors] = useState({
    email: '',
    contact: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateContact = (contact) => {
    const contactRegex = /^[0-9]{1,11}$/;
    return contactRegex.test(contact);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    let error = '';

    if (name === 'email' && !validateEmail(value)) {
      error = 'Invalid email address';
    } else if (name === 'contact' && !validateContact(value)) {
      error = 'Contact number should not be greater than 11 digits';
    }

    setFormErrors({
      ...formErrors,
      [name]: error,
    });
  };
  // Validation of contact us form ends here

  const contactusCall = async () => {
    try {
      const contactusObj = {
        email: formValues?.email,
        name: formValues?.name,
        contact: formValues?.contact,
        message: formValues?.message,
      };
      const queryResponse = await axios.post(contactus, contactusObj);
      console.log('I got data FROM backend', queryResponse?.data);
      console.log(queryResponse?.data?.data?.success);
      if (queryResponse?.data?.type == 'success') {
        console.log('SUCESS');
        toast.success(queryResponse?.data?.data);
        setFormValues({
          name: '',
          email: '',
          contact: '',
          message: '',
        });
      } else {
        toast.error(queryResponse?.data?.data);
      }
    } catch (err) {
      //console.log(err);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    contactusCall();
  };

  return (
    <div className='contact-us-parent-div'>
      <div className='contact-us-top-div'>
        <p className='common-font'>Contact Us</p>
      </div>
      <div>
        <MaxWidthWrapper>
          <div className='contact-us-content'>
            <div className='contact-us-content-top-div'>
              <p className='common-font contact-us-content-top-div-heading'>
                Let’s stay connected
              </p>
              <p className='common-font contact-us-content-top-div-subheading'>
                It's never been easier to get in touch with Flex. Call us, use
                our live chat widget or <br />
                email and we'll get back to you as soon as possible!
              </p>
            </div>
            <div className='contact-us-content-bottom-div'>
              <div className='contact-us-content-bottom-div-left'>
                <div className='contact-us-content-bottom-div-left-left'>
                  <div className='contact-us-content-bottom-div-left-inner'>
                    <img src={mail} alt='mail' className='common-image-style' />
                    <p className='common-font contact-us-content-heading-common '>
                      Email
                    </p>
                    <p className='common-font contact-us-content-heading-common contact-us-content-subheading-common'>
                      info@laptopsquare.pk
                    </p>
                  </div>
                  <div className='contact-us-content-bottom-div-left-inner'>
                    <img
                      src={location}
                      alt='location'
                      className='common-image-style'
                    />
                    <p className='common-font contact-us-content-heading-common '>
                      Address
                    </p>
                    <p className='common-font contact-us-content-subheading'>
                      LG 53, Rania Mall Bank Road Saddar Rawalpindi.
                    </p>
                  </div>
                </div>
                <div className='contact-us-content-bottom-div-left-left  contact-us-content-bottom-div-left-left-extra'>
                  <div className='contact-us-content-bottom-div-left-inner'>
                    <img
                      src={phone}
                      alt='phone'
                      className='common-image-style'
                    />
                    <p className='common-font contact-us-content-heading-common'>
                      Phone
                    </p>
                    <p className='common-font contact-us-content-heading-common contact-us-content-subheading-common'>
                      +923001230326
                    </p>
                  </div>
                  <div className='contact-us-content-bottom-div-left-inner'>
                    <img
                      src={social}
                      alt='social'
                      className='common-image-style'
                    />
                    <p className='common-font contact-us-content-heading-common'>
                      Socials
                    </p>
                    <div className='contact-us-icon-div'>
                      <img
                        onClick={() => {
                          window.open(
                            'https://www.facebook.com/laptopsquarepk?mibextid=ZbWKwL'
                          );
                        }}
                        src={Facebook}
                        alt='Facebook'
                      />
                      <img
                        onClick={() => {
                          window.open(
                            'https://x.com/laptopsquarepk?t=eDWTg5EH8LDoGrnwloLR4Q&s=09'
                          );
                        }}
                        src={Twitter}
                        alt='Twitter'
                      />
                      {/* <img src={Instagram} alt='Instagram' /> */}
                      {/* <img src={LinkedIn} alt='LinkedIn' /> */}
                      <svg
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          window.open('https://youtube.com/@laptopsquarepk');
                        }}
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 50 50'
                        width='25px'
                        height='25px'
                      >
                        <path d='M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z' />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className='contact-us-content-bottom-div-right'>
                <form
                  action=''
                  className='contact-us-form-div'
                  onSubmit={handleSubmit}
                >
                  <div className='contact-us-common-field'>
                    <label className='common-font' htmlFor='name'>
                      Name
                    </label>
                    <TextField
                      className='contact-us-common-fiel-design'
                      id='outlined-basic'
                      variant='outlined'
                      placeholder='johndoe'
                      name='name'
                      value={formValues.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='contact-us-common-field'>
                    <label className='common-font' htmlFor='email'>
                      Email
                    </label>
                    <TextField
                      className='contact-us-common-fiel-design'
                      id='outlined-basic'
                      variant='outlined'
                      placeholder='pat@shuffle.dev'
                      name='email'
                      value={formValues.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(formErrors.email)}
                      helperText={formErrors.email}
                    />
                  </div>
                  <div className='contact-us-common-field'>
                    <label className='common-font' htmlFor='contact'>
                      Contact
                    </label>
                    <TextField
                      className='contact-us-common-fiel-design'
                      id='outlined-basic'
                      variant='outlined'
                      placeholder='03xxxxxxxxx'
                      name='contact'
                      value={formValues.contact}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(formErrors.contact)}
                      helperText={formErrors.contact}
                    />
                  </div>
                  <div className='contact-us-common-field'>
                    <label className='common-font' htmlFor='message'>
                      Message
                    </label>
                    <TextField
                      className='common-font contact-us-common-fiel-design'
                      id='outlined-multiline-static'
                      multiline
                      rows={7}
                      placeholder='Your message...'
                      name='message'
                      value={formValues.message}
                      onChange={handleChange}
                    />
                  </div>

                  <div className='contact-us-button-div'>
                    <Buttons
                      size='large'
                      variant='contained'
                      disabled={
                        formValues?.name != '' &&
                        formValues?.email != '' &&
                        formValues?.contact != '' &&
                        formValues?.message != ''
                          ? formErrors?.name == '' ||
                            (formErrors?.name == undefined &&
                              formErrors?.contact == '') ||
                            formErrors?.contact == undefined
                            ? false
                            : true
                          : true
                      }
                    >
                      Send
                    </Buttons>
                  </div>
                </form>
              </div>
            </div>
            <ToastContainer
              position='top-right'
              autoClose={2000}
              hideProgressBars
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme='dark'
            />
          </div>
        </MaxWidthWrapper>
      </div>
    </div>
  );
}

export default ContactUs;
