import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getcategories } from '../../Api/ApiUrl';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';

import loader from '../../assets/Lottie/loader.json';

// importing images
import latop from '../../assets/icons/laptop.png';
import game from '../../assets/icons/game.png';
import keyboard from '../../assets/icons/keyboard.png';
import mouse from '../../assets/icons/mouse.png';
import harddrive from '../../assets/icons/harddrive.png';
import charger from '../../assets/icons/charger.png';
// importing components
import MaxWidthWrapper from '../../Components/MaxWidthWrapper';
//importing css
import '../../Css/LandingPageCss/Categories.css';
import '../../App.css';
import CategoriesComponent from '../../Components/CategoriesComponent';
function Categories() {
  const cat = [
    {
      img: latop,
      text: 'Laptop',
      location: '/laptop',
    },
    {
      img: game,
      text: 'Game Accessories',
      location: '/laptop',
    },
    {
      img: keyboard,
      text: 'Keyboards',
      location: '/laptop',
    },
    {
      img: mouse,
      text: 'Mouse',
      location: '/laptop',
    },
    {
      img: harddrive,
      text: 'Hard Drive',
      location: '/laptop',
    },
    {
      img: charger,
      text: 'Charger',
      location: '/laptop',
    },
  ];

  //States
  const [categories, setCategories] = useState([]);
  const [hideProducts, sethideProducts] = useState(true);

  useEffect(() => {
    const getCategories = async () => {
      try {
        const categoriesDetails = await axios.get(getcategories);
        console.log('I got data FROM backend', categoriesDetails?.data?.data);
        setCategories(categoriesDetails?.data?.data);

        sethideProducts(false);
      } catch (err) {
        //console.log(err);
      }
    };
    getCategories();
  }, []);
  if (hideProducts) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Lottie
          animationData={loader}
          autoplay={true}
          loop={true}
          style={{ width: '300px' }}
        />
      </div>
    );
  }
  return (
    <div className='categories-parent-container'>
      <MaxWidthWrapper>
        <div className='categories-container'>
          <div className='categories-top-container'>
            <div className='categories-top-container-left'>
              <p className='common-font '>Browse By Category</p>
            </div>
            <div className='categories-top-container-right'>
              <svg
                style={{ cursor: 'pointer' }}
                xmlns='http://www.w3.org/2000/svg'
                width='32'
                height='32'
                viewBox='0 0 32 32'
                fill='none'
              >
                <path
                  d='M21.3338 27.6658C21.0683 27.6671 20.8136 27.5613 20.6271 27.3725L9.96042 16.7058C9.57048 16.3154 9.57048 15.6829 9.96042 15.2925L20.6271 4.62581C21.0211 4.25867 21.6351 4.2695 22.0159 4.65032C22.3967 5.03113 22.4076 5.64513 22.0404 6.03915L12.0804 15.9991L22.0404 25.9591C22.4304 26.3496 22.4304 26.9821 22.0404 27.3725C21.8539 27.5613 21.5992 27.6671 21.3338 27.6658Z'
                  fill='black'
                />
              </svg>

              <svg
                style={{ cursor: 'pointer' }}
                xmlns='http://www.w3.org/2000/svg'
                width='32'
                height='32'
                viewBox='0 0 32 32'
                fill='none'
              >
                <path
                  d='M10.6662 4.33419C10.9317 4.33293 11.1864 4.43867 11.3729 4.62752L22.0396 15.2942C22.4295 15.6846 22.4295 16.3171 22.0396 16.7075L11.3729 27.3742C10.9789 27.7413 10.3649 27.7305 9.98408 27.3497C9.60327 26.9689 9.59243 26.3549 9.95958 25.9609L19.9196 16.0009L9.95958 6.04085C9.56964 5.65043 9.56964 5.01795 9.95958 4.62752C10.1461 4.43867 10.4008 4.33293 10.6662 4.33419Z'
                  fill='black'
                />
              </svg>
            </div>
          </div>
          <div className='categories-bottom-parent-container'>
            {categories.map((data) => {
              return (
                <div className='categories-bottom-container'>
                  <CategoriesComponent
                    icon={data?.attributes?.image}
                    text={data?.attributes?.name}
                    id={data?.id}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </MaxWidthWrapper>
    </div>
  );
}

export default Categories;
