import React from 'react';
import '../Css/Components/MaxWidthWrapper.css';
function MaxWidthWrapper({ children }) {
  return (
    <div className='max-width-wrapper-parent'>
      <div className='max-width-wrapper'>{children}</div>
    </div>
  );
}

export default MaxWidthWrapper;
