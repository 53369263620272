import React from 'react';
//importing images
import Laptop from '../../assets/images/hero-image.png';
//importing css
import '../../Css/LandingPageCss/HeroSection.css';
import '../../App.css';

// importing componet
import MaxWidthWrapper from '../../Components/MaxWidthWrapper';
import Buttons from '../../Components/Buttons/Buttons';
import Paragraphs from '../../Components/Buttons/Paragraphs';

function HeroSection() {
  return (
    <div className='hero-sectio-top-container'>
      <MaxWidthWrapper>
        <div className='hero-section-content'>
          <div className='hero-section-left-div'>
            {/* <p className='common-font hero-section-left-div-top-text'>
              Pro.Beyond.
            </p> */}
            <h1 className='common-font hero-section-left-div-center-text'>
              Laptop <span>Square </span>
            </h1>
            <h2 className='common-font hero-section-left-div-bottom-text'>
              Get your lap a perfect laptop
            </h2>
            <div className='desktop-view'>
              <Buttons
                size='large'
                variant='outlined2'
                border='have-border'
                onClick={() => {
                  const phoneNumber = '923001230326';
                  const text = 'How may I help you';
                  const whatsappLink = `//api.whatsapp.com/send?phone=${phoneNumber}&text=${text}`;
                  window.open(whatsappLink, '_blank');
                }}
              >
                <Paragraphs
                  size='plarge'
                  variant='poutlined2'
                  border='phave-border'
                >
                  Shop Now
                </Paragraphs>
              </Buttons>
            </div>

            <div className='mobile-view'>
              <Buttons
                size='large'
                variant='outlined3'
                border='have-border'
                onClick={() => {
                  const phoneNumber = '923001230326';
                  const text = 'How may I help you';
                  const whatsappLink = `//api.whatsapp.com/send?phone=${phoneNumber}&text=${text}`;
                  window.open(whatsappLink, '_blank');
                }}
              >
                <Paragraphs
                  size='plarge'
                  variant='poutlined4'
                  border='phave-border'
                >
                  Shop Now
                </Paragraphs>
              </Buttons>
            </div>
          </div>
          <div className='hero-section-right-div'>
            <img
              src={Laptop}
              alt='Laptop Image'
              className='hero-section-image'
            />
          </div>
        </div>
      </MaxWidthWrapper>
    </div>
  );
}

export default HeroSection;
