import React from 'react';
import '../../Css/Components/Buttons.css';
function Buttons({
  children,
  variant,
  size,
  border,
  className,
  onClick,
  disabled,
}) {
  var disabledCss;
  if (!disabled) {
    disabled = false;
    console.log(disabled);
    disabledCss = '';
  } else {
    disabledCss = 'disabledCss';
  }
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`common-button ${variant} ${size} ${border} ${className} ${disabledCss}`}
    >
      {children}
    </button>
  );
}

export default Buttons;
