import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import lottiePath from '../../assets/Lottie/Empty box.json';
import { getAllproducts } from '../../Api/ApiUrl';
// importing images
import product1 from '../../assets/images/product1.png';
//importing css
import '../../Css/LandingPageCss/Products.css';
import '../../App.css';
import MaxWidthWrapper from '../../Components/MaxWidthWrapper';
import ProductComponent from '../../Components/ProductComponent';
function ReleatedProducts() {
  const [products, setAllproducts] = useState([]);
  // const products = [
  //   {
  //     img: product1,
  //     title: 'Acer Predator Helios 300 i7-12700H 16 GB 1TB Gaming',
  //     price: '18,000',
  //     discount: '20,000',
  //   },
  //   {
  //     img: product1,
  //     title: 'Acer Predator Helios 300 i7-12700H 16 GB 1TB Gaming',
  //     price: '18,000',
  //     discount: '20,000',
  //   },
  //   {
  //     img: product1,
  //     title: 'Acer Predator Helios 300 i7-12700H 16 GB 1TB Gaming',
  //     price: '18,000',
  //     discount: '20,000',
  //   },
  //   {
  //     img: product1,
  //     title: 'Acer Predator Helios 300 i7-12700H 16 GB 1TB Gaming',
  //     price: '18,000',
  //     discount: '20,000',
  //   },
  // ];

  useEffect(() => {
    const getAllProducts = async () => {
      try {
        const getAllProducts = await axios.post(getAllproducts);
        console.log('I got data FROM backend', getAllProducts?.data?.data);
        const products = getAllProducts?.data?.data;
        let displayedProducts = [];
        if (products?.length > 8) {
          displayedProducts = products.slice(0, 5);
        } else {
          displayedProducts = products;
        }
        setAllproducts(displayedProducts);
      } catch (err) {
        //console.log(err);
      }
    };
    getAllProducts();
  }, []);
  return (
    <div className='products-parent-div'>
      <MaxWidthWrapper>
        <div className='categories-container'>
          <div className='categories-top-container'>
            <div className='categories-top-container-left'>
              <p className='common-font active '>Related Products</p>
            </div>
            <div className='categories-top-container-right'>
              <p className='common-font'></p>
            </div>
          </div>
          <>
            {products.length > 0 ? (
              <div className='products-bottom-parent-container'>
                {products.map((data, index) => {
                  console.log('i am inside current product');
                  return (
                    <ProductComponent
                      key={index}
                      img={data?.product_information?.images[0]}
                      title={data?.product_information?.name}
                      price={data?.product_information?.price}
                      id={data?.product_uuid}
                    />
                  );
                })}
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 0,
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <Lottie
                  animationData={lottiePath}
                  autoplay={true}
                  loop={true}
                  style={{ width: '40%' }}
                />
                <p className='common-font laptop-total-products'>
                  No product found :(
                </p>
              </div>
            )}
          </>
          {/* <div className='products-bottom-parent-container'>
            {products.map((data, index) => {
              return (
                <ProductComponent
                  key={index}
                  img={data?.product_information?.images[0]}
                  title={data?.product_information?.name}
                  price={data?.product_information?.price}
                  id={data?.product_uuid}
                />
              );
            })}
          </div> */}
        </div>
      </MaxWidthWrapper>
    </div>
  );
}

export default ReleatedProducts;
