import React, { useState } from 'react';

import '../Css/Components/ProductComponent.css';
import '../App.css';
//importing routing link and navigator
import { useNavigate } from 'react-router-dom';

import Buttons from './Buttons/Buttons';
import Paragraphs from './Buttons/Paragraphs';
function ProductComponent({ img, title, price, discount, id }) {
  const [isLiked, setIsLiked] = useState(false);
  const navigate = useNavigate();
  return (
    <div
      className='product-component-parent-container'
      onClick={() => {
        navigate(`/product-details/${encodeURIComponent(id)}`);
      }}
    >
      <div className='product-component-container'>
        <div className='product-component-heart'>
          {/* {isLiked ? (
            <svg
              onClick={() => {
                setIsLiked(!isLiked);
              }}
              xmlns='http://www.w3.org/2000/svg'
              width='32'
              height='32'
              viewBox='0 0 32 32'
              fill='none'
            >
              <path
                d='M5.93488 18.5449L15.316 27.3575C15.6404 27.6622 15.8026 27.8146 16.0007 27.8146C16.1987 27.8146 16.3609 27.6622 16.6853 27.3575L26.0664 18.5449C28.6747 16.0948 28.9914 12.0628 26.7977 9.23541L26.3853 8.70377C23.761 5.32142 18.4935 5.88867 16.6495 9.75218C16.3891 10.2979 15.6122 10.2979 15.3518 9.75218C13.5078 5.88867 8.24028 5.32142 5.61604 8.70377L5.20357 9.23541C3.00992 12.0628 3.32665 16.0948 5.93488 18.5449Z'
                fill='#FF0000'
                stroke='#FF0000'
                stroke-width='1.4'
              />
            </svg>
          ) : (
            <svg
              onClick={() => {
                setIsLiked(!isLiked);
              }}
              xmlns='http://www.w3.org/2000/svg'
              width='32'
              height='32'
              viewBox='0 0 32 32'
              fill='none'
            >
              <path
                d='M5.93488 18.5449L15.316 27.3575C15.6404 27.6622 15.8026 27.8146 16.0007 27.8146C16.1987 27.8146 16.3609 27.6622 16.6853 27.3575L26.0664 18.5449C28.6747 16.0948 28.9914 12.0628 26.7977 9.23541L26.3853 8.70377C23.761 5.32142 18.4935 5.88867 16.6495 9.75218C16.3891 10.2979 15.6122 10.2979 15.3518 9.75218C13.5078 5.88867 8.24028 5.32142 5.61604 8.70377L5.20357 9.23541C3.00992 12.0628 3.32665 16.0948 5.93488 18.5449Z'
                stroke='#919191'
                stroke-opacity='0.77'
                stroke-width='1.4'
              />
            </svg>
          )} */}
        </div>
        <div className='product-component-img-div'>
          <img src={img} alt='product image' />
        </div>
        <div className='product-component-content'>
          <p className='common-font product-component-content-title'>{title}</p>
          <p className='common-font product-component-content-price'>
            Rs {price}
          </p>
          {discount && (
            <p className='common-font product-component-content-price product-component-content-discount'>
              Rs {discount}
            </p>
          )}
        </div>
        <div className='product-component-button'>
          <Buttons
            size='large'
            variant='contained'
            border='have-border'
            onClick={() => {
              const phoneNumber = '923001230326';
              const text = 'How may I help you';
              const whatsappLink = `//api.whatsapp.com/send?phone=${phoneNumber}&text=${text}`;
              window.open(whatsappLink, '_blank');
            }}
          >
            <Paragraphs
              size='plarge'
              variant='pcontained'
              border='phave-border'
            >
              Buy Now
            </Paragraphs>
          </Buttons>
        </div>
      </div>
    </div>
  );
}

export default ProductComponent;
