import React from 'react';
import MaxWidthWrapper from '../../Components/MaxWidthWrapper';
import '../../Css/AboutUs/AboutUs.css';

import profile from '../../assets/images/profileimage.png';
import profile2 from '../../assets/images/profileimage2.png';
import profile3 from '../../assets/images/profileimage3.png';
import profile4 from '../../assets/images/profileimage4.png';
import profile5 from '../../assets/images/profileimage5.png';

import aboutuslaptop from '../../assets/images/about-us-laptop.png';
import CustomerReview from '../../Components/CustomerReview';
function AboutUs() {
  const reviews = [
    {
      review:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare.',
      profile: profile,
      name: 'Name Surname',
      position: 'Position, Company name',
    },
    {
      review:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare.',
      profile: profile2,
      name: 'Name Surname',
      position: 'Position, Company name',
    },
    {
      review:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare.',
      profile: profile3,
      name: 'Name Surname',
      position: 'Position, Company name',
    },
    {
      review:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare.',
      profile: profile4,
      name: 'Name Surname',
      position: 'Position, Company name',
    },
    {
      review:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare.',
      profile: profile4,
      name: 'Name Surname',
      position: 'Position, Company name',
    },
    {
      review:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare.',
      profile: profile5,
      name: 'Name Surname',
      position: 'Position, Company name',
    },
  ];
  return (
    <div className='about-us-parent-div'>
      <div className='about-us-top-div'>
        <p className='common-font'>About Us</p>
      </div>
      <MaxWidthWrapper>
        <div className='about-us-flex-column'>
          <div className='about-us-upper-bottom-div'>
            <div className='about-us-upper-div-left'>
              <p className='common-font about-us-common-heading'>
                About Laptop Square
              </p>
              <p className='common-font subheading'>
                Your premier destination for high-quality laptops and
                exceptional service. At Laptop Square, we pride ourselves on
                delivering top-tier laptops straight to your doorstep, ensuring
                both convenience and reliability. Backed by unparalleled
                customer support, choose Laptop Square for the perfect blend of
                performance, quality, and customer satisfaction.
              </p>
            </div>

            <div className='about-us-upper-div-right'>
              <img src={aboutuslaptop} alt='laptop image' />
            </div>
          </div>

          {/* <div className='about-us-upper-bottom-div about-us-upper-bottom-div-extra'>
            <div className='about-us-bottom'>
              <p className='common-font about-us-common-heading'>
                Loved by our customers
              </p>
              <p className='common-font about-us-common-heading about-us-common-subheading'>
                Hundreds of 5-star reviews of Laptop Square award winning
                software
              </p>
            </div>
            <div className='about-us-review-loop'>
              {reviews.map((data) => {
                return (
                  <CustomerReview
                    review={data?.review}
                    profile={data?.profile}
                    name={data?.name}
                    position={data?.position}
                  />
                );
              })}
            </div>
          </div> */}
        </div>
      </MaxWidthWrapper>
    </div>
  );
}

export default AboutUs;
